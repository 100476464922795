import React, { Component } from "react";
import { connect } from "react-redux";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import { createChallengeForm } from "../../../actions/forms";
import Select2ComponentId from "../../Select2/Select2ComponentId";
import { brandList } from "../../../actions/host";

class GameSetupComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass: true, winCriteria: null, selectedWinCriteria: 1, currentLang: this.props.defaultLang };
    this.changeWinCriteria = this.changeWinCriteria.bind(this);
    this.translatedValues = this.translatedValues.bind(this);
  }

  changeWinCriteria(selectedWinCriteria) {
    this.setState({ selectedWinCriteria: selectedWinCriteria });
  }
  selectWinCriteria(data) {
    this.setState({ winCriteria: data.winCriteria });
    const winCriteriaId = data.winCriteria[0] && data.winCriteria[0].id > 3 ? data.winCriteria[0].id : 1;
    this.props.createChallengeFormUpdateField("winCriterionId", winCriteriaId);
    this.props.createChallengeFormUpdateField("previewBanner", data.thumb);
    this.props.createChallengeFormUpdateField("challengeImageUrl", data.bannerImage);

    this.props.createChallengeFormUpdateField("promoTitle", data.promoTitle);
    this.props.createChallengeFormUpdateField("promoSubTitle", data.promoSubTitle);
    this.props.createChallengeFormUpdateField("promoLogo", data.promoLogo);
    this.props.createChallengeFormUpdateField("promoBackgroundOverlayImage", data.promoBackgroundOverlayImage);
    this.props.createChallengeFormUpdateField("promoBackgroundImage", data.promoBackgroundImage);
    this.props.createChallengeFormUpdateField("promoBackgroundColor2", data.promoBackgroundColor2);
    this.props.createChallengeFormUpdateField("promoBackgroundColor", data.promoBackgroundColor);
    
    this.translatedValues(this.state.currentLang, "promoTitle", data.promoTitle);
    this.translatedValues(this.state.currentLang, "promoSubTitle", data.promoSubTitle);
    this.translatedValues(this.state.currentLang, "promoLogo", data.promoLogo);
    this.translatedValues(this.state.currentLang, "promoBackgroundOverlayImage", data.promoBackgroundOverlayImage);
    this.translatedValues(this.state.currentLang, "promoBackgroundImage", data.promoBackgroundImage);
    this.translatedValues(this.state.currentLang, "promoBackgroundColor2", data.promoBackgroundColor2);
    this.translatedValues(this.state.currentLang, "promoBackgroundColor", data.promoBackgroundColor);
  }
  componentDidMount() {
    this.props.invalidatebrandList();
    this.props.fetchbrandListIfNeeded();
    let game = this.props.gamesData[0];

    this.props.createChallengeFormUpdateField("gamesName", game.name);
    this.props.createChallengeFormUpdateField("gamesId", game.id);
    this.props.createChallengeFormUpdateField("winCriterionId", 1);
    this.props.createChallengeFormUpdateField("previewBanner", game.thumb);
    this.props.createChallengeFormUpdateField("challengeImageUrl", game.bannerImage);
    
    this.props.createChallengeFormUpdateField("promoTitle", game.promoTitle);
    this.props.createChallengeFormUpdateField("promoSubTitle", game.promoSubTitle);
    this.props.createChallengeFormUpdateField("promoLogo", game.promoLogo);
    this.props.createChallengeFormUpdateField("promoBackgroundOverlayImage", game.promoBackgroundOverlayImage);
    this.props.createChallengeFormUpdateField("promoBackgroundImage", game.promoBackgroundImage);
    this.props.createChallengeFormUpdateField("promoBackgroundColor2", game.promoBackgroundColor2);
    this.props.createChallengeFormUpdateField("promoBackgroundColor", game.promoBackgroundColor);

    this.translatedValues(this.state.currentLang, "promoTitle", game.promoTitle);
    this.translatedValues(this.state.currentLang, "promoSubTitle", game.promoSubTitle);
    this.translatedValues(this.state.currentLang, "promoLogo", game.promoLogo);
    this.translatedValues(this.state.currentLang, "promoBackgroundOverlayImage", game.promoBackgroundOverlayImage);
    this.translatedValues(this.state.currentLang, "promoBackgroundImage", game.promoBackgroundImage);
    this.translatedValues(this.state.currentLang, "promoBackgroundColor2", game.promoBackgroundColor2);
    this.translatedValues(this.state.currentLang, "promoBackgroundColor", game.promoBackgroundColor);
    this.translatedValues(this.state.currentLang, "challengeImageUrl", game.bannerImage);

    this.setState({ winCriteria: this.props.gamesData[0].winCriteria });
  }
  translatedValues(locale, field, value) {
    var translationData = this.props.createChallengeForm.fields.challengeTranslation.value ? this.props.createChallengeForm.fields.challengeTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale)
    if (existRecord.length > 0) {
      existRecord[0][field] = value
    } else {
      var translationObj = { languageId: locale };
      translationObj = { ...translationObj, [field]: value }
      translationData.push(translationObj);
    }
    this.props.createChallengeFormUpdateField('challengeTranslation', translationData)
  }
  render() {
    const { hostList, languagesList, defaultLang } = this.props;

    const category = {};
    let platform = "";
    const selectHostOption = hostList.map((data, index) => {
      let ownerBrandId = this.props.createChallengeForm.fields.selectHost.value;
      if (ownerBrandId == data.id) {
        platform = data.env.name;
      }
      return {
        ...category,
        id: data.id,
        value: data.name,
        avatar: data.avatar
      };
    });

    let selectPlayerOption = [];
    for (let i = 2; i <= 15; i++) {
      const value = Math.pow(2, i);
      selectPlayerOption.push({ id: value, value: value + " players" });
    }

    const selectSubscription = [{ id: 1, value: "None" }];
    let challengeTranslationData = this.props.createChallengeForm.fields.challengeTranslation.value ? this.props.createChallengeForm.fields.challengeTranslation.value : [];
    let nameExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.state.currentLang)
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mt-5 mt-lg-0">
              <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                {languagesList.map((language, index) => {
                  return (
                    <li
                      key={index}
                      className="nav-item col text-center"
                    >
                      <span
                        className={
                          "a nav-link" +
                          (this.state.currentLang == language.id ? " active show " + ((this.props.defaultLang == language.id) && (this.props.createChallengeForm.fields.challengeName
                            .error)
                            ? "nav-danger"
                            : "") : "")
                        }
                        id="pills-today-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-today"
                        aria-selected="true"
                        onClick={() => this.setState({
                          currentLang: language.id
                        })}
                      >
                        {language.locale}
                      </span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="padder-main">
            <div className="row ">
              <div className="col-sm-12">
                <div className="form-group with-lable w-100">
                  <AppInputComponent
                    inputClass={
                      this.state.currentLang == this.props.defaultLang ? !this.props.createChallengeForm.fields.challengeName
                        .error || (nameExistOrNot[0] && nameExistOrNot[0]['name'])
                        ? "form-control effect-16"
                        : "form-control effect-17" : "form-control effect-16"
                    }
                    divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                    labelText="Enter challenge name"
                    withLabel="true"
                    maxlength="50"
                    value={nameExistOrNot[0] && nameExistOrNot[0]['name'] ? nameExistOrNot[0]['name'] : ""}
                    onChange={event => {
                      if (this.state.currentLang == this.props.defaultLang) {
                        this.props.createChallengeFormResetError("challengeName");
                        this.props.createChallengeFormUpdateField(
                          "challengeName",
                          this.state.currentLang == this.props.defaultLang ? event.target.value : ''
                        )
                      }
                      this.translatedValues(this.state.currentLang, 'name', event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6  form-info">
                <div className="row">
                  <div className="col-sm-12">
                    <h5 className="bold">Create New Arcadex</h5>
                  </div>
                  <ul className=" list-inline d-flex flex-row flex-wrap w-100 mt-4">
                    {this.props.gamesData && this.props.gamesData.length > 0
                      ? this.props.gamesData.map((game, index) => (
                        <li
                          className="list-group-control col-sm-6 mt-2"
                          key={index}
                        >
                          <div className="select-game-box">
                            <label className="custom-control custom-radio w-100 mb-0 d-flex justify-content-center align-items-center">
                              <div className="labelc-content d-inline-flex align-items-center">
                                <img src={game.thumb} alt="" />
                                <p className="mb-0 ml-3">{game.name}</p>
                              </div>
                              <div className="label-new ml-auto">
                                <input
                                  className="custom-control-input has-content"
                                  type="radio"
                                  name="gamesName"
                                  value={game.name + "/" + game.id}
                                  checked={
                                    this.props.createChallengeForm.fields
                                      .gamesName.value === game.name ||
                                      (!this.props.createChallengeForm.fields
                                        .gamesName.value &&
                                        index === 0)
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={event => {
                                    var value = event.target.value;
                                    var gameData = value.split("/");
                                    this.props.createChallengeFormUpdateField(
                                      "gamesName",
                                      gameData[0]
                                    );
                                    this.props.createChallengeFormUpdateField(
                                      "gamesId",
                                      gameData[1]
                                    );
                                    this.selectWinCriteria(game);
                                  }}
                                />
                                <span className="custom-control-indicator" />
                              </div>
                            </label>
                          </div>
                        </li>
                      ))
                      : ""}
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6  section-info">
                <div className="row">
                  <div className="col-sm-12 mt-5 mt-lg-0">
                    <h5 className="bold">Win Criteria</h5>
                    <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                      {this.state.winCriteria &&
                        this.state.winCriteria.length > 0
                        ? this.state.winCriteria.map((criteria, index) => (
                          <li
                            className="nav-item col text-center"
                            key={index}
                          >
                            <span
                              className={
                                "a nav-link" +
                                ([1, 4].indexOf(criteria.id) > -1
                                  ? " active show"
                                  : "")
                              }
                              id="pills-today-tab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="pills-today"
                              aria-selected="true"
                            >
                              {criteria.description}
                            </span>
                          </li>
                        ))
                        : ""}
                    </ul>
                  </div>
                  <div className="col-sm-12 mt-4 zIndex-2">
                    <h5 className="bold">Host</h5>
                    <div className="form-group with-lable my-3">
                      <div className="input-group-wrapper">
                        <Select2ComponentId
                          selectClass={
                            this.props.createChallengeForm.fields.selectHost
                              .error
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input"
                          }
                          onChange={event => {
                            this.props.createChallengeFormResetError(
                              "selectHost"
                            );
                            this.props.createChallengeFormUpdateField(
                              "selectHost",
                              event.id
                            );
                            this.props.createChallengeFormUpdateField(
                              "selectHostName",
                              event.text
                            );
                            // this.props.createChallengeFormUpdateField(
                            //   "selectHostAvatar",
                            //   event.avatar
                            // );
                            selectHostOption
                              .filter(data => data.id === event.id)
                              .map((item, index) => {
                                this.props.createChallengeFormUpdateField(
                                  "previewLogo",
                                  item.avatar
                                );
                                this.translatedValues(this.state.currentLang, "logoUrl",  item.avatar);
                                this.props.createChallengeFormUpdateField(
                                  "logoUrl",
                                  item.avatar
                                );
                              });
                          }}
                          selectOptions={selectHostOption}
                          labelText={"Select host"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12  mt-4">
                    <h5 className="bold">Platform</h5>
                    <div className=" form-group with-lable my-3">
                      <div className="input-group-wrapper">
                        <input
                          type="text"
                          placeholder="Platform"
                          className="form-control effect-16 "
                          tabIndex="1"
                          value={platform}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 zIndex-1">
                    <h5 className="bold">Number of players</h5>
                    <div className="form-group with-lable my-3">
                      <div className="input-group-wrapper">
                        <Select2ComponentId
                          selectClass={
                            this.props.createChallengeForm.fields.selectPlayer
                              .error
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input"
                          }
                          onChange={event => {
                            this.props.createChallengeFormResetError(
                              "selectPlayer"
                            );
                            this.props.createChallengeFormUpdateField(
                              "selectPlayer",
                              event.id
                            );
                          }}
                          selectOptions={selectPlayerOption}
                          labelText={"Select player"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h5 className="bold">Subscription</h5>
                    <div className="form-group with-lable my-3">
                      <div className="input-group-wrapper">
                        <Select2ComponentId
                          selectClass={
                            this.props.createChallengeForm.fields
                              .selectSubscription.errorMsg
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input"
                          }
                          onChange={event => {
                            this.props.createChallengeFormResetError(
                              "selectSubscription"
                            );
                            this.props.createChallengeFormUpdateField(
                              "selectSubscription",
                              event.id
                            );
                          }}
                          selectOptions={selectSubscription}
                          labelText={"Select subscription"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  createChallengeFormSubmit: createChallengeForm.submit,
  createChallengeFormUpdateField: createChallengeForm.updateField,
  createChallengeFormReset: createChallengeForm.reset,
  createChallengeFormResetField: createChallengeForm.resetField,
  createChallengeFormSetError: createChallengeForm.setError,
  createChallengeFormSetSuccess: createChallengeForm.setSuccess,
  createChallengeFormResetError: createChallengeForm.resetError,
  createChallengeFormResetSuccess: createChallengeForm.resetSuccess,

  fetchbrandListIfNeeded: brandList.fetchIfNeeded,
  invalidatebrandList: brandList.invalidate
};

const mapStateToProps = state => {
  const { forms, user, host, languages } = state;
  return {
    createChallengeForm: forms.createChallengeForm,
    hostList: host.brandList ? host.brandList.items : [],
    hostListIsFetching: host.brandList ? host.brandList.isFetching : false,
    stats: user.item.adminStats.CHALLENGES,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameSetupComponent);
