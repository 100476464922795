import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../assets/images/avatar-demo.png";
import visualTimer from "../../assets/images/svg/visual-timer.svg";
import Countdown from "./Countdown";
import addIcon from "../../assets/images/svg/add.svg";
import cardSm from "../../assets/images/card-sm.png";
import { deleteRaffleForm } from "../../actions/forms";

class RafflesCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  toggleDeleteMenu() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }
  render() {
    const { showDeleteButton } = this.props;
    const { visible } = this.state;
    return (
      <>
        <div className="swiper-slide d-flex justify-content-end">
          {this.props.id === "add" ? (
            <a href="/create-raffle" className="text-center">
              <div className="card enter-raffles admin-raffle">
                <div className="card-body">
                  <div className="card-img d-flex justify-content-center align-items-center">
                    <img src={addIcon} className="w-25" alt="" />
                  </div>
                </div>
                <div className="card-footer text-center">
                  <div className="raffle-timer">
                    <div className="spacer" />
                  </div>
                </div>
              </div>
            </a>
          ) : (
              <>
                <div className={"card enter-raffles" + (visible ? " car-selected" : "")}>
                  <div className="card-body">
                    {showDeleteButton && (
                      <span
                        className={"close-icon " + (visible ? " d-block" : "")}
                        onClick={e => {
                          e.preventDefault();
                          this.toggleDeleteMenu();
                        }}
                      >
                        &times;
                  </span>
                    )}
                    <a
                      href={"/raffle/" + this.props.id}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        className="card-img"
                        src={this.props.itemUrl ? this.props.itemUrl : cardSm}
                        alt="Card image cap"
                        onError={(e)=>(e.target.src=cardSm)}
                      />
                      <div className="card-info text-center d-flex flex-column align-items-center justify-content-center">
                        <figure className="w-100">
                          <img
                            src={this.props.brandAvatar ? this.props.brandAvatar : ProfileImage}
                            className="profile-pic-56"
                            alt=""
                          />
                          <figcaption className="d-flex align-items-center justify-content-center">
                            <h5 className="bold text-white">
                              {this.props.itemDescription && this.props.points
                                ? this.props.itemDescription +
                                " + " +
                                this.props.points +
                                " Tokens"
                                : !this.props.itemDescription && this.props.points
                                  ? this.props.points + " Tokens"
                                  : this.props.itemDescription && !this.props.points
                                    ? this.props.itemDescription
                                    : ""}
                            </h5>
                          </figcaption>
                        </figure>
                        <span className="btn btn-pink btn-block dark ">
                          {this.props.entryFee
                            ? this.props.entryFee
                            : 0}{" "}
                          Tokens
                    </span>
                      </div>
                    </a>
                  </div>
                  <div className="card-footer text-center">
                    <div className="raffle-timer">
                      <img src={visualTimer} alt="" />
                      <h5 className="bold text-secondary mt-2">
                        <Countdown
                          action={this.startEndTimehandler}
                          targetDate={this.props.timeOfEnd}
                          format={{ hour: "HH", minute: "MM", second: "SS" }}
                          totalHours={true}
                          leadingZero
                        />
                      </h5>
                    </div>
                  </div>
                  <>
                    {showDeleteButton && visible && (
                      <div className="delete-confirmation-wrapper">
                        <p className="mb-1 bold ml-2 message-text">Are you sure ?</p>
                        <div className="button-wrapper">
                          <button
                            onClick={() => {
                              this.props.enableNewsFormUpdateField(
                                "raffleId",
                                this.props.id
                              );
                              this.props.enableNewsFormSubmit({ id: this.props.id, languageId: this.props.defaultLang });
                              this.toggleDeleteMenu();
                            }}
                            type="button"
                            className="btn btn-pink light py-3 btn-small mt-2 btn-left custom-btn-left"
                          >
                            Yes
                     </button>
                          <button
                            onClick={() => {
                              this.toggleDeleteMenu();
                            }}
                            type="button"
                            className=" btn btn-pink light py-3 btn-small mt-2 ml-4 btn-right custom-btn-right"
                          >
                            No
             </button>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </>
            )}
        </div>
      </>
    );
  }
}

const defaultProps = {
  id: 1,
  avatar: ProfileImage,
  name: "Telkom Esports",
  cardClass: "card popular-arcadex",
  cardHeaderClass: "card-header light"
};

RafflesCardComponent.defaultProps = defaultProps;
const mapStateToProps = state => {
  const { user } = state;
  return {
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 1
  };
};
const mapDispatchToProps = {
  enableNewsFormUpdateField: deleteRaffleForm.updateField,
  enableNewsFormSubmit: deleteRaffleForm.submit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RafflesCardComponent);

