import React, { Component } from "react";
import { connect } from "react-redux";
import "swiper/dist/css/swiper.css";
import camerasvg from "../../../assets/images/svg/camera.svg";
import navCloseSvg from "../../../assets/images/svg/nav-close.svg";
import {
  updateChallengeForm
} from "../../../actions/forms";
import { fileUpload } from "../../../services/fileUpload.service";
import AdPlacement from "./AdPlacement";
import token from "../../../assets/images/token.png";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import FeaturedBannerComponent from './FeaturedBannerComponent';
import BrandBannerComponent from './BrandBannerComponent';
import { brandList } from "../../../actions/host";

class BrandingStepComponent extends Component {
  constructor(props) {
    super(props);
    this.changeAdsPlace = this.changeAdsPlace.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeUploadFile = this.removeUploadFile.bind(this);
    this.changeFeaturedState = this.changeFeaturedState.bind(this);
    this.translatedValues = this.translatedValues.bind(this);
    this.state = { adsPlacements: false, currentLang: this.props.defaultLang  };
  }
  componentDidMount(){
    this.setState((props, state) => {
      return{
        game: this.props.gameLists.filter(data => data.id == this.props.updateChallengeForm.fields.gamesId.value)
      }
    });
  }
  changeAdsPlace() {
    this.setState({
      adsPlacements: !this.state.adsPlacements
    });
  }

  changeFeaturedState(featured) {
    this.props.updateChallengeFormUpdateField("isFeatured", !featured);
  }
  
  backToDefault(field){
    switch(field){
      case "promoLogo":
		    this.props.updateChallengeFormUpdateField("promoLogo", this.state.game[0]['promoLogo'] );
        this.props.updateChallengeFormUpdateField("isCustomPromoLogo", false);
        break;
      case "promoBackgroundOverlayImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundOverlayImage", this.state.game[0]['promoBackgroundOverlayImage'] );
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundOverlayImage", false);
        break;
      case "promoBackgroundImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundImage", this.state.game[0]['promoBackgroundImage'] );
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundImage", false);
        break;
    }
  }
  removeCustomUploadFile(field) {
    switch(field){
      case "promoLogo":
        this.props.updateChallengeFormUpdateField("promoLogo", "");
        this.props.updateChallengeFormUpdateField("isCustomPromoLogo", true);
        break;
      case "promoBackgroundOverlayImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundOverlayImage", "");
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundOverlayImage", true);
        break;
      case "promoBackgroundImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundImage", "");
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundImage", true);
        break;
    }
  }

  removeUploadFile(field) {
    if (this.state.currentLang == this.props.defaultLang) {
      this.props.updateChallengeFormUpdateField(field, "");
    }
    var translationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == this.state.currentLang)
    existRecord[0][field] = ''
    this.props.updateChallengeFormUpdateField('challengeTranslation', translationData)
  }

  uploadFile(event, imgType, imgFormat) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      if (fileSize < 2) {
        if (event.target.files[0].type !== "") {
          const fileType = event.target.files[0].type
            .split("/")[1]
            .toLowerCase();
          if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
            this.props.updateChallengeFormSetError(imgFormat, "NOT_EMPTY");
            this.props.updateChallengeFormResetError(imgType);
            return false;
          }
        } else {
          return false;
        }
      } else {
        this.props.updateChallengeFormSetError(imgType, "NOT_EMPTY");
        this.props.updateChallengeFormResetError(imgFormat);
        return false;
      }

      this.props.updateChallengeFormResetError(imgType);
      this.props.updateChallengeFormResetError(imgFormat);
      fileUpload(event.target.files)
        .then(url => {
          this.translatedValues(this.state.currentLang, imgType, url);
          if (this.state.currentLang == this.props.defaultLang) {
            this.props.updateChallengeFormUpdateField(imgType, url);
          }
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.updateChallengeFormUpdateField(imgType, "");
    }
  }
  translatedValues(locale, field, value) {
    var translationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale)
    if (existRecord.length > 0) {
      existRecord[0][field] = value
    } else {
      var translationObj = { languageId: locale };
      translationObj = { ...translationObj, [field]: value }
      translationData.push(translationObj);
    }
    this.props.updateChallengeFormUpdateField('challengeTranslation', translationData)
  }

  render() {
    const { languagesList } = this.props;
    let brandId = this.props.challengeDetails.brandId;
    let challengeTranslationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    let dataExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.state.currentLang)
    // console.log(this.state.brand);
    return (
      <>
        {(this.props.challengeDetails.challengeTranslation || this.props.challengeDetails) && dataExistOrNot.length > 0 ? (
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="col-sm-12 mt-5 mt-lg-0">
                  <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                    {languagesList.map((language, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item col text-center"
                        >
                          <span
                            className={
                              "a nav-link" +
                              (this.state.currentLang == language.id ? " active show " + ((this.props.defaultLang == language.id) && (this.props.updateChallengeForm.fields.challengeImageUrl
                                .error || this.props.updateChallengeForm.fields.logoUrl
                                  .error)
                                ? "nav-danger"
                                : "") : "")
                            }
                            id="pills-today-tab"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-today"
                            aria-selected="true"
                            onClick={() => this.setState({
                              currentLang: language.id
                            })}
                          >
                            {language.locale}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="padder-main">
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <h5 className="bold mb-3">Branding</h5>
                </div>
                <BrandBannerComponent
                      translatedValues={this.translatedValues}
                      uploadFile={this.uploadFile}
                      removeUploadFile={this.removeUploadFile}
                      currentLang={this.state.currentLang}
                      challengeDetails={this.props.challengeDetails}
                    >
                  </BrandBannerComponent>

                <div className="row mb-3 ml-3">
                  <div className="col-sm-2 select-game-box">
                    <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                      <div className="ml-auto">
                        <input
                          className="fill-control-input"
                          type="checkbox"
                          checked={
                            this.props.updateChallengeForm.fields.isFeatured.value
                          }
                          onChange={e => {
                            this.changeFeaturedState(
                              this.props.updateChallengeForm.fields.isFeatured
                                .value
                            );
                          }}
                        />
                        <span className="fill-control-indicator backend" />
                      </div>
                    </label>
                  </div>
                  <div className="col-sm-4 mt-1">
                    <p className="mb-0 bold ml-2">Featured</p>
                  </div>
                </div>
               
                {this.props.updateChallengeForm.fields.isFeatured.value ? (
                  <>
                  
                    <FeaturedBannerComponent
                      translatedValues={this.translatedValues}
                      uploadFile={this.uploadFile}
                      currentLang={this.state.currentLang}
                      challengeDetails={this.props.challengeDetails}
                      backToDefault={this.backToDefault}
                      removeCustomUploadFile={this.removeCustomUploadFile}
                    >
                    </FeaturedBannerComponent>
                  </>
                ) : (
                    ""
                  )}

                <div className="col-sm-12">
                  <h5 className="bold mb-3">Ads Placements</h5>
                  <ul className="nav nav-pills mb-4 d-flex justify-content-center align-items-center w-25">
                    <li className="nav-item col text-center">
                      <span
                        className={
                          "a nav-link" +
                          (this.state.adsPlacements ? "" : " active show")
                        }
                        onClick={this.changeAdsPlace}
                      >
                        Web
                    </span>
                    </li>
                    <li className="nav-item col text-center">
                      <span
                        className={
                          "a nav-link" +
                          (!this.state.adsPlacements ? "" : " active show")
                        }
                        onClick={this.changeAdsPlace}
                      >
                        Mobile
                    </span>
                    </li>
                  </ul>
                  <section className="tab-content">
                    <div
                      id="webadv"
                      className={
                        "tab-pane fade" +
                        (this.state.adsPlacements ? "" : " active show")
                      }
                    >
                      {this.props.stats
                        .filter((placement, index) => {
                          return placement.type === "web";
                        })
                        .map((placement, index) => {
                          return (
                            <AdPlacement
                              key={index}
                              {...placement}
                              onUploadComplete={(imgType, url) => {
                                this.props.updateChallengeFormUpdateField(
                                  imgType,
                                  url
                                );
                              }}
                              fileType={placement.mediaType}
                              onRemove={imgType => {
                                this.props.updateChallengeFormUpdateField(
                                  imgType,
                                  ""
                                );
                              }}
                              value={
                                this.props.updateChallengeForm.fields[
                                  placement.type + "-" + placement.placeCode
                                ] != undefined
                                  ? this.props.updateChallengeForm.fields[
                                    placement.type + "-" + placement.placeCode
                                  ].value
                                  : ""
                              }
                              ownerBrandId={
                                this.props.updateChallengeForm.fields[
                                  placement.type +
                                  "-" +
                                  placement.placeCode +
                                  "-" +
                                  "ownerBrandID"
                                ] != undefined
                                  ? this.props.updateChallengeForm.fields[
                                    placement.type +
                                    "-" +
                                    placement.placeCode +
                                    "-" +
                                    "ownerBrandID"
                                  ].value
                                  : ""
                              }
                              brandId={brandId}
                            />
                          );
                        })}
                    </div>
                    <div
                      id="mobile-adv"
                      className={
                        "tab-pane fade" +
                        (!this.state.adsPlacements ? "" : " active show")
                      }
                    >
                      {this.props.stats
                        .filter((placement, index) => {
                          return placement.type === "mobile";
                        })
                        .map((placement, index) => {
                          return (
                            <AdPlacement
                              key={index}
                              {...placement}
                              onUploadComplete={(imgType, url) => {
                                this.props.updateChallengeFormUpdateField(
                                  imgType,
                                  url
                                );
                              }}
                              fileType={placement.mediaType}
                              onRemove={imgType => {
                                this.props.updateChallengeFormUpdateField(
                                  imgType,
                                  ""
                                );
                              }}
                              value={
                                this.props.updateChallengeForm.fields[
                                  placement.type + "-" + placement.placeCode
                                ] != undefined
                                  ? this.props.updateChallengeForm.fields[
                                    placement.type + "-" + placement.placeCode
                                  ].value
                                  : ""
                              }
                              ownerBrandId={
                                this.props.updateChallengeForm.fields[
                                  placement.type +
                                  "-" +
                                  placement.placeCode +
                                  "-" +
                                  "ownerBrandID"
                                ] != undefined
                                  ? this.props.updateChallengeForm.fields[
                                    placement.type +
                                    "-" +
                                    placement.placeCode +
                                    "-" +
                                    "ownerBrandID"
                                  ].value
                                  : ""
                              }
                              brandId={brandId}
                            />
                          );
                        })}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        ) : (
            ""
          )}
      </>
    );
  }
}
const mapDispatchToProps = {
  updateChallengeFormSubmit: updateChallengeForm.submit,
  updateChallengeFormUpdateField: updateChallengeForm.updateField,
  updateChallengeFormReset: updateChallengeForm.reset,
  updateChallengeFormResetField: updateChallengeForm.resetField,
  updateChallengeFormSetError: updateChallengeForm.setError,
  updateChallengeFormSetSuccess: updateChallengeForm.setSuccess,
  updateChallengeFormResetError: updateChallengeForm.resetError,
  updateChallengeFormResetSuccess: updateChallengeForm.resetSuccess,
  // fetchbrandListIfNeeded: brandList.fetchIfNeeded,
  // invalidatebrandList: brandList.invalidate
};

const mapStateToProps = state => {
  const { forms, user, languages, host } = state;
  // console.log("host:", host.brandList ? host.brandList.items : []);
  return {
    updateChallengeForm: forms.updateChallengeForm,
    stats: user.item.adminStats.CHALLENGES,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0,
    hostList: host.brandList ? host.brandList.items : [],
    hostListIsFetching: host.brandList ? host.brandList.isFetching : false,
    gameLists: user.item.adminStats.ARCADEX_GAMES || null,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandingStepComponent);
