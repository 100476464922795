import React, { Component } from "react";
import "./CreateNewsComponent.scss";
import navArrowRight from "../../assets/images/svg/nav-arrow-right-blue.svg";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
// import AppTextAreaComponent from "../../layouts/components/sidebars/AppTextAreaComponent";
import cameraSvg from "../../assets/images/svg/camera.svg";
import navCloseSvg from "../../assets/images/svg/nav-close.svg";
import Select2ComponentId from "../Select2/Select2ComponentId";
import { fileUpload } from "../../services/fileUpload.service";
import Editor from "../../components/Editor/Editor";
import {
  exec,
  queryCommandState,
  queryCommandValue
} from "../../components/Editor/editor-pell";
class CreateNewsComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.state = {
      removeCategory: false,
      isActive: true
    };
  }
  componentDidMount() {
    this.props.invalidateNewsCategories();
    this.props.fetchNewsCategoriesIfNeeded();
  }
  uploadFile(event, imgType) {
    if (event.target.files.length > 0) {
      this.props.createNewsFormResetError(imgType);
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type
        ? event.target.files[0].type.split("/")[1].toLowerCase()
        : "";
      if (event.target.files[0].type !== "") {
        if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
          this.props.createNewsFormSetError(imgType, "FILE_TYPE");
          return false;
        }
      } else {
        this.props.createNewsFormSetError(imgType, "FILE_TYPE");
        return false;
      }
      if (fileSize > 2) {
        this.props.createNewsFormSetError(imgType, "FILE_SIZE");
        return false;
      }

      this.props.createNewsFormResetError(imgType);
      fileUpload(event.target.files)
        .then(url => {
          this.props.createNewsFormUpdateField(imgType, url);
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.createNewsFormUpdateField(imgType, "");
    }
  }
  removeUpload(imgType) {
    this.props.createNewsFormUpdateField(imgType, "");
  }
  render() {
    const { newsCategoriesList, statsPlatform } = this.props;
    const categoryDropDown = newsCategoriesList.items.map((data, index) => {
      return {
        id: data.id,
        value: data.description
      };
    });
    const platformDropdown = statsPlatform.map((data, index) => {
      return {
        id: data.id,
        value: data.name
      };
    });
    const actions = [
      {
        name: "heading1",
        icon: "H1",
        result: () => {
          if (queryCommandValue("formatBlock") !== "h1") {
            exec("formatBlock", "<h1>");
          } else {
            exec("formatBlock", "<div>");
          }
        },
        state: () => {
          return queryCommandValue("formatBlock") === "h1";
        }
      },
      {
        name: "heading2",
        icon: "H2",
        result: () => {
          if (queryCommandValue("formatBlock") !== "h2") {
            exec("formatBlock", "<h2>");
          } else {
            exec("formatBlock", "<div>");
          }
        },
        state: () => {
          return queryCommandValue("formatBlock") === "h2";
        }
      },
      "bold",
      "paragraph",
      {
        name: "link",
        icon: "&#128279;",
        result: () => {
          const linkUrl = window.prompt("Enter the link URL");
          if (linkUrl) {
            exec("createLink", linkUrl);
          }
        }
      },
      {
        name: "olist",
        icon: '<span class="icon-ol"></span>',
        state: () => {
          return queryCommandState("insertOrderedList");
        }
      }
    ];
    return (
      <section className="premium" id="rootwizard">
        <section className="container-fluid tab-content-wrapper newsPageForm">
          <div className="row">
            <div className="col-sm-12">
              <div className="container">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    let postParam = {
                      categoryId: this.props.createNewsForm.fields.categoryId
                        .value,
                      title: this.props.createNewsForm.fields.title.value,
                      content: this.props.createNewsForm.fields.content.value,
                      source: this.props.createNewsForm.fields.source.value,
                      imageUrl: this.props.createNewsForm.fields.imageUrl.value,
                      thumbImageUrl: this.props.createNewsForm.fields
                        .thumbImageUrl.value,
                      isActive: this.state.isActive,
                      envId: this.props.createNewsForm.fields.envId.value
                    };
                    if (!this.props.createNewsForm.fields.categoryId.value) {
                      this.props.createNewsFormSetError(
                        "categoryId",
                        "NOT_EMPTY"
                      );
                    }
                    if (!this.props.createNewsForm.fields.envId.value) {
                      this.props.createNewsFormSetError("envId", "NOT_EMPTY");
                    }
                    if (!this.props.createNewsForm.fields.title.value) {
                      this.props.createNewsFormSetError("title", "NOT_EMPTY");
                    }
                    if (!this.props.createNewsForm.fields.source.value) {
                      this.props.createNewsFormSetError("source", "NOT_EMPTY");
                    }
                    if (!this.props.createNewsForm.fields.imageUrl.value) {
                      this.props.createNewsFormSetError(
                        "imageUrl",
                        "NOT_EMPTY"
                      );
                    }
                    if (!this.props.createNewsForm.fields.thumbImageUrl.value) {
                      this.props.createNewsFormSetError(
                        "thumbImageUrl",
                        "NOT_EMPTY"
                      );
                    }

                    if (
                      !this.props.createNewsForm.fields.categoryId.value ||
                      !this.props.createNewsForm.fields.title.value ||
                      !this.props.createNewsForm.fields.imageUrl.value ||
                      !this.props.createNewsForm.fields.thumbImageUrl.value ||
                      !this.props.createNewsForm.fields.source.value ||
                      !this.props.createNewsForm.fields.envId.value
                    ) {
                      return false;
                    }

                    this.props.createNewsFormSubmit(postParam);
                    this.setState({
                      removeCategory: true
                    });
                  }}
                >
                  <div className="padder-main">
                    <div className="row ">
                      <div className="form-group with-lable w-100">
                        <div className="col-sm-12  mt-6 text-right d-flex justify-content-start mb-5 back-arrow">
                          <a
                            href="/news"
                            className="view-all d-flex align-items-center"
                          >
                            <span>
                              <img src={navArrowRight} alt="" />
                            </span>
                            <h5 className="bold text-secondary"> Back </h5>
                          </a>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-4 zIndex-1 mb-5">
                            <div className="form-group with-lable my-3 mt-4">
                              <div className="input-group-wrapper">
                                <Select2ComponentId
                                  selectClass={
                                    this.props.createNewsForm.fields.categoryId
                                      .error
                                      ? "form-control effect-17 select-2-input"
                                      : "form-control effect-16 select-2-input"
                                  }
                                  onChange={event => {
                                    this.props.createNewsFormResetError(
                                      "categoryId"
                                    );
                                    this.props.createNewsFormUpdateField(
                                      "categoryId",
                                      event.id
                                    );
                                  }}
                                  selectOptions={categoryDropDown}
                                  onSubmitRemove={this.state.removeCategory}
                                  labelText="Select Category"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 select mb-5">
                            <Select2ComponentId
                              selectOptions={platformDropdown}
                              labelText="Select Platform"
                              selectClass={
                                "select-2-input " +
                                (this.props.createNewsForm.fields.envId.error
                                  ? "form-control effect-17 "
                                  : "form-control effect-16")
                              }
                              onChange={event => {
                                this.props.createNewsFormResetError("envId");
                                this.props.createNewsFormUpdateField(
                                  "envId",
                                  event.id
                                );
                              }}
                            />
                          </div>
                          <div className="row mb-4 ml-1">
                            <div className="col-sm-2 select-game-box">
                              <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                                <div className="ml-auto">
                                  <input
                                    className="fill-control-input"
                                    type="checkbox"
                                    checked={this.state.isActive}
                                    readOnly
                                  />
                                  <span className="fill-control-indicator backend" />
                                </div>
                              </label>
                            </div>
                            <div className="col-sm-4 mt-1">
                              <p className="mb-0 bold ml-2">Active</p>
                            </div>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <div className="form-group with-lable mt-4 ">
                              <AppInputComponent
                                labelText="Title"
                                inputClass={
                                  this.props.createNewsForm.fields.title.error
                                    ? "form-control effect-17"
                                    : "form-control effect-16"
                                }
                                onChange={event => {
                                  this.props.createNewsFormResetError("title");
                                  this.props.createNewsFormUpdateField(
                                    "title",
                                    event.target.value
                                  );
                                }}
                                value={
                                  this.props.createNewsForm.fields.title.value
                                }
                                withLabel={true}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <div className="form-group with-lable mt-4 ">
                              <AppInputComponent
                                labelText="Source"
                                inputClass={
                                  this.props.createNewsForm.fields.source.error
                                    ? "form-control effect-17"
                                    : "form-control effect-16"
                                }
                                onChange={event => {
                                  this.props.createNewsFormResetError("source");
                                  this.props.createNewsFormUpdateField(
                                    "source",
                                    event.target.value
                                  );
                                }}
                                value={
                                  this.props.createNewsForm.fields.source.value
                                }
                                withLabel={true}
                              />
                            </div>
                          </div>

                          <div className="col-sm-12 mb-4">
                            {/* <AppTextAreaComponent
                              divClass="form-group with-lable mt-5"
                              labelText="Content"
                              onChange={event => {
                                this.props.createNewsFormUpdateField(
                                  "content",
                                  event.target.value
                                );
                                this.props.createNewsFormResetError("content");
                              }}
                              value={
                                this.props.createNewsForm.fields.content.value
                              }
                            /> */}
                            <h5 className="bold mb-3">Content</h5>
                             <Editor
                            onChange={() => {
                              this.props.createNewsFormUpdateField("content",  this.editor.getContent());
                            }
                            }
                            actions={actions}
                            contentClass={"pell-content"}
                            ref={editor => {
                              this.editor = editor;
                            }}
                            defaultContent={this.props.createNewsForm.fields.content.value}
                          />
                          </div>
                          <div className="col-sm-12  zIndex-1 mb-4">
                            <h5 className="bold mb-3">Upload News Image</h5>
                            <div className="upload-image d-flex align-items-center ">
                              {this.props.createNewsForm.fields.imageUrl
                                .value ? (
                                <div className="upload-button image-area-2 col h-100 d-flex flex-column justify-content-center align-items-center">
                                  <img
                                    src={
                                      this.props.createNewsForm.fields.imageUrl
                                        .value
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    name="img[]"
                                    className="file"
                                    onChange={e =>
                                      this.uploadFile(e, "imageUrl")
                                    }
                                    ref={input => (this.inputImg = input)}
                                  />
                                  <div
                                    className={
                                      "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                                      (this.props.createNewsForm.fields.imageUrl
                                        .error ||
                                      this.props.createNewsForm.fields.imageUrl
                                        .errorMsg == "FILE_TYPE" ||
                                      this.props.createNewsForm.fields.imageUrl
                                        .errorMsg == "FILE_SIZE"
                                        ? " add-new-rewards-danger"
                                        : "")
                                    }
                                    onClick={() => this.inputImg.click()}
                                  >
                                    <img
                                      src={cameraSvg}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <p className="choose-photo mb-0 bold">
                                      Upload News Image
                                    </p>
                                    <p className="mt-4 small text-center">
                                      Minimum size 1920px width 200px height
                                      <br />{" "}
                                      <span
                                        className={
                                          this.props.createNewsForm.fields
                                            .imageUrl.errorMsg == "FILE_SIZE"
                                            ? "file-size-danger"
                                            : ""
                                        }
                                      >
                                        * Maximum size 2 MB{" "}
                                      </span>
                                      <br />{" "}
                                      <span
                                        className={
                                          this.props.createNewsForm.fields
                                            .imageUrl.errorMsg == "FILE_TYPE"
                                            ? "file-size-danger"
                                            : ""
                                        }
                                      >
                                        * Supported file types : jpg, jpeg and
                                        png
                                      </span>
                                    </p>
                                  </div>
                                </>
                              )}
                              <div className="col-auto ">
                                {this.props.createNewsForm.fields.imageUrl
                                  .value ? (
                                  <div
                                    className="popup-dismiss"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() =>
                                      this.removeUpload("imageUrl")
                                    }
                                  >
                                    {" "}
                                    <img src={navCloseSvg} alt="" />{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12  zIndex-1 mb-4">
                            <h5 className="bold mb-3">Upload Thumb Image</h5>
                            <div className="upload-image d-flex align-items-center ">
                              {this.props.createNewsForm.fields.thumbImageUrl
                                .value ? (
                                <div className="upload-button image-area-2 col h-100 d-flex flex-column justify-content-center align-items-center">
                                  <img
                                    src={
                                      this.props.createNewsForm.fields
                                        .thumbImageUrl.value
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    name="img[]"
                                    className="file"
                                    onChange={e =>
                                      this.uploadFile(e, "thumbImageUrl")
                                    }
                                    ref={input =>
                                      (this.inputwebHeaderImg = input)
                                    }
                                  />
                                  <div
                                    className={
                                      "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                                      (this.props.createNewsForm.fields
                                        .thumbImageUrl.error ||
                                      this.props.createNewsForm.fields
                                        .thumbImageUrl.errorMsg ==
                                        "FILE_TYPE" ||
                                      this.props.createNewsForm.fields
                                        .thumbImageUrl.errorMsg == "FILE_SIZE"
                                        ? " add-new-rewards-danger"
                                        : "")
                                    }
                                    onClick={() =>
                                      this.inputwebHeaderImg.click()
                                    }
                                  >
                                    <img
                                      src={cameraSvg}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <p className="choose-photo mb-0 bold">
                                      Upload Thumb Image
                                    </p>
                                    <p className="mt-4 small text-center">
                                      Minimum size 1920px width 200px height
                                      <br />{" "}
                                      <span
                                        className={
                                          this.props.createNewsForm.fields
                                            .thumbImageUrl.errorMsg ==
                                          "FILE_SIZE"
                                            ? "file-size-danger"
                                            : ""
                                        }
                                      >
                                        * Maximum size 2 MB{" "}
                                      </span>
                                      <br />
                                      <span
                                        className={
                                          this.props.createNewsForm.fields
                                            .thumbImageUrl.errorMsg ==
                                          "FILE_TYPE"
                                            ? "file-size-danger"
                                            : ""
                                        }
                                      >
                                        * Supported file types : jpg, jpeg and
                                        png
                                      </span>
                                    </p>
                                  </div>
                                </>
                              )}
                              <div className="col-auto ">
                                {this.props.createNewsForm.fields.thumbImageUrl
                                  .value ? (
                                  <div
                                    className="popup-dismiss"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() =>
                                      this.removeUpload("thumbImageUrl")
                                    }
                                  >
                                    {" "}
                                    <img src={navCloseSvg} alt="" />{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 d-block d-sm-flex align-items-center justify-content-center mt-5">
                            <button
                              type="submit"
                              className="[ btn btn-pink light  py-3 btn-preview dark mt-2 ml-4]"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
export default CreateNewsComponent;
