import React, { Component } from "react";
import { connect } from "react-redux";
import "swiper/dist/css/swiper.css";
import RewardsCardComponent from "./RewardsCardComponent/RewardsCardComponent";
import { updateChallengeForm } from "../../../actions/forms";

class RewardsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLang: this.props.defaultLang
    };
    this.getRewardPlace = this.getRewardPlace.bind(this);
  }

  getRewardPlace(no) {
    switch (no) {
      case 5:
        return "5-8th";
        break;
      case 6:
        return "9-16th";
        break;
      case 7:
        return "17-32nd";
        break;
      case 8:
        return "33-64th";
        break;
      case 9:
        return "65-128th";
        break;
      case 10:
        return "129-256th";
        break;
      case 11:
        return "257-512nd";
        break;
      case 12:
        return "513-1024th";
        break;
      case 13:
        return "1025-2048th";
        break;
      case 14:
        return "2049-4096th";
        break;
      case 15:
        return "4097-8192nd";
        break;
      case 16:
        return "8193-16384th";
        break;
      case 17:
        return "16385-32768th";
        break;
      default:
        return no;
        break;
    }
  }

  render() {
    let rewardArray = this.props.updateChallengeForm.fields.rewards.value
      ? this.props.updateChallengeForm.fields.rewards.value
      : [];

    let challengeTranslationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    let rewardsExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.state.currentLang)
    return (
      <>
        {challengeTranslationData && rewardsExistOrNot.length > 0 ? (
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="col-sm-12 mt-5 mt-lg-0">
                  <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                    {this.props.languagesList.map((language, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item col text-center"
                        >
                          <span
                            className={
                              "a nav-link" +
                              (this.state.currentLang == language.id ? " active show" : "")
                            }
                            onClick={() => this.setState({
                              currentLang: language.id
                            })}
                          >
                            {language.locale}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="padder-main">
              <div className="row">
                <div className="col-sm-12  mt-2 mb-5">
                  <h1>
                    Challenge{" "}
                    {rewardsExistOrNot[0]['name']}
                  </h1>
                </div>
              </div>
              <div className="row">
                {rewardArray.map((rewardItem, i) => {
                  return (
                    <RewardsCardComponent
                      {...rewardItem}
                      key={i}
                      locale={this.state.currentLang}
                      rewardIndex={i}
                      rewardPlaceData={this.getRewardPlace(i + 1)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
            ""
          )}
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  const { forms, user, languages } = state;
  return {
    updateChallengeForm: forms.updateChallengeForm,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardsComponent);
